<template>
    <Modal :footer-actions="actions" slot-class="d-flex flex-grow-1">
        <template #title><i class="far fa-map" /> {{ title }}</template>
        <MapPolygon
            :edit="isEditable"
            class="flex-grow-1"
            :default-map-center="defaultMapCenter"
            style="min-height: calc(90vh - 150px)"
            ref="polygon"
            @drawing="(val) => (isDrawing = val)"
            :is-multiple="isMultiple"
            :extra-polygons="extraPolygons"
            :extra-polygon-style="extraPolygonStyle"
            :default-map-zoom="defaultMapZoom"
            v-model="local"
            @selected-area="(val) => (selectedArea = val)"
        />
    </Modal>
</template>

<script>
import Modal from "@Platon/components/extended/Modal.vue"
import MapPolygon from "@Platon/components/form/controls/MapPolygon.vue"

export default {
    name: "MapPolygonModal",
    components: { MapPolygon, Modal },

    props: {
        value: {},

        onValue: {},

        isEditable: {
            default: false,
            type: Boolean
        },

        defaultMapCenter: {},
        defaultMapZoom: {},
        extraPolygons: {},
        extraPolygonStyle: {},
        isMultiple: {}
    },

    data() {
        return {
            local: this.value,
            isDrawing: false,

            selectedArea: 0
        }
    },

    computed: {
        title() {
            if (this.local) {
                return this.$l("platon.edit", "Таҳрирлаш")
            } else {
                return this.$l("platon.pick", "Белгилаш")
            }
        },

        actions() {
            return [
                {
                    text: this.$l("platon.save", "Сақлаш"),
                    disabled: this.isDrawing,
                    handler: () => {
                        if (this.onValue) {
                            this.onValue(this.local, this.selectedArea)
                        }
                    }
                },

                {
                    text: this.$l("platon.cancel", "Бекор қилиш"),
                    handler: () => {}
                }
            ]
        }
    }
}
</script>

<style></style>
